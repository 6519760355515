<template>
    <div>
        <b-form-checkbox-group v-model="localPermissions">
            <div>
                <b-row>
                    <b-col class="mt-2" v-for="(permissionGroup, index) in permissions" :key="index" lg="6">
                        <h6>{{permissionGroup.name}}</h6>
                        <b-form-group class="mt-50 mb-50"
                                      v-for="(permission, permissionIndex) in permissionGroup.permissions"
                                      :key="index + '-' + permissionIndex">
                            <b-form-checkbox :disabled="reverseLinkedPermissions.has(permission.id) && localPermissions.some((perm) => reverseLinkedPermissions.get(permission.id).includes(perm))" :value="permission.id">{{ permission.name }}</b-form-checkbox>
                        </b-form-group>

                    </b-col>
                </b-row>
            </div>

        </b-form-checkbox-group>
    </div>
</template>
<script>

    import {BFormCheckboxGroup, BFormCheckbox, BFormGroup, BRow, BCol} from 'bootstrap-vue'
    export default {
        components: {
            BFormCheckboxGroup,
            BFormCheckbox,
            BRow,
            BCol,
            BFormGroup
        },
        props: {
            value: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                permissions: [
                    {
                        name: 'User management',
                        permissions: [
                            { name: 'Manage users', id: 101 },
                            { name: 'Manage roles', id: 102 },
                            { name: 'Add users', id: 103 },
                            { name: 'Edit users', id: 104 },
                            { name: 'Remove users', id: 105 }
                        ]
                    },
                    {
                        name: 'Outlets',
                        permissions: [
                            { name: 'View all', id: 201 },
                            { name: 'Add outlet', id: 202 },
                            { name: 'Edit outlet', id: 203 },
                            { name: 'Approve outlet', id: 204 },
                            { name: 'Deactivate outlet', id: 207 },
                            { name: 'Add outlet in any area', id: 205 },
                            { name: 'Remove outlet', id: 206 },
                            { name: 'Add segment', id: 210 },
                            { name: 'Edit segment', id: 211 },
                            { name: 'Remove segment', id: 212 }
                        ]
                    },
                    {
                        name: 'Areas',
                        permissions: [
                            { name: 'View all', id: 301 },
                            { name: 'Add areas', id: 302 },
                            { name: 'Edit areas', id: 303 },
                            { name: 'Remove areas', id: 304 }
                        ]
                    },
                    {
                        name: 'Products',
                        permissions: [
                            { name: 'Add products', id: 402 },
                            { name: 'Edit products', id: 403 },
                            { name: 'Remove products', id: 404 }
                        ]
                    },
                    {
                        name: 'Promotional items',
                        permissions: [
                            { name: 'View all', id: 501 },
                            { name: 'Add promotional items', id: 502 },
                            { name: 'Edit promotional items', id: 503 },
                            { name: 'Assign promotional items', id: 504 },
                            { name: 'Remove promotional items', id: 505 }
                        ]
                    },
                    {
                        name: 'Warehouse',
                        permissions: [
                            { name: 'View all', id: 601 },
                            { name: 'Add warehouses', id: 602 },
                            { name: 'Edit warehouses', id: 603 },
                            { name: 'Remove warehouses', id: 604 }
                        ]
                    },
                    {
                        name: 'Config',
                        permissions: [{ name: 'Manage system config', id: 703 }]
                    },
                    {
                        name: 'Distribution vehicles',
                        permissions: [
                            { name: 'View distribution vehicles', id: 801 },
                            { name: 'Edit vehicles', id: 802 },
                            { name: 'Edit own vehicle load', id: 803 },
                            { name: 'Add vehicles', id: 804 },
                            { name: 'Remove vehicles loads', id: 805 },
                            { name: 'Edit all vehicles loads', id: 806 }
                        ]
                    },
                    {
                        name: 'User requests',
                        permissions: [
                            { name: 'View all user requests', id: 904 },
                            { name: 'Add user requests', id: 901 },
                            { name: 'Close user requests', id: 902 },
                            { name: 'Remove user requests', id: 903 }
                        ]
                    },
                    {
                        name: 'Sales',
                        permissions: [
                            { name: 'Edit sales', id: 1001 },
                            { name: 'View all sales', id: 1002 },
                            { name: 'View for assigned areas', id: 1003 },
                            { name: 'Remove sales', id: 1004 }
                        ]
                    },

                    {
                        name: 'Surveys',
                        permissions: [
                            { name: 'View', id: 1101 },
                            { name: 'Edit surveys', id: 1102 },
                            { name: 'Submit surveys', id: 1103 },
                            { name: 'Remove surveys', id: 1104 },
                            { name: 'Remove survey results', id: 1105 }
                        ]
                    }


                ],

                linkedPermissions: [
                    /*{id: 201, linkedPermissions: [301]},
                    {id: 303, linkedPermissions: [301]},
                    {id: 603, linkedPermissions: [601]},
                    {id: 803, linkedPermissions: [801, 601]},
                    {id: 801, linkedPermissions: [601]},
                    {id: 101, linkedPermissions: [801, 102]}*/
                ],

                reverseLinkedPermissions: new Map()
            }
        },
        computed: {
            localPermissions: {
                set(val) {
                    const localVal = val
                    for (let i = 0; i < this.linkedPermissions.length; i++) {
                        if (val.includes(this.linkedPermissions[i].id)) {
                            for (let x = 0; x < this.linkedPermissions[i].linkedPermissions.length; x++) {
                                if (!val.includes(this.linkedPermissions[i].linkedPermissions[x])) localVal.push(this.linkedPermissions[i].linkedPermissions[x])
                            }
                        }
                    }
                    this.$emit('input', localVal)
                },
                get() {
                    return this.value
                }
            }
        },
        created() {
            for (let i = 0; i < this.linkedPermissions.length; i++) {
                for (let x = 0; x < this.linkedPermissions[i].linkedPermissions.length; x++) {
                    if (!this.reverseLinkedPermissions.has(this.linkedPermissions[i].linkedPermissions[x])) this.reverseLinkedPermissions.set(this.linkedPermissions[i].linkedPermissions[x], [])
                    if (!this.reverseLinkedPermissions.get(this.linkedPermissions[i].linkedPermissions[x]).includes(this.linkedPermissions[i].id)) this.reverseLinkedPermissions.get(this.linkedPermissions[i].linkedPermissions[x]).push(this.linkedPermissions[i].id)
                }

            }
        }
    }
</script>